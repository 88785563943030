export const isVideoUrl = (url: string) => {
  const videoUrlPatterns = [
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/,
    /^(https?:\/\/)?(www\.)?vimeo\.com\/.+$/,
    /^(https?:\/\/)?(www\.)?loom\.com\/.+$/,
  ];

  const videoExtensions = ['mp4', 'webm', 'mov', 'avi', 'wmv', 'flv', 'mkv'];
  const extension = url.split('.').pop()?.toLowerCase();

  const isPlatformVideo = videoUrlPatterns.some((pattern) => pattern.test(url));

  const isDirectVideo = videoExtensions.includes(extension ?? '');

  return isPlatformVideo || isDirectVideo;
};

export const getYouTubeEmbedUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (
      urlObj.hostname === 'www.youtube.com' ||
      urlObj.hostname === 'youtube.com'
    ) {
      const videoId = new URLSearchParams(urlObj.search).get('v');
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    if (urlObj.hostname === 'youtu.be') {
      const videoId = urlObj.pathname.split('/').pop();
      if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
      }
    }

    return null;
  } catch {
    return null;
  }
};

export const getVimeoEmbedUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (
      urlObj.hostname === 'www.vimeo.com' ||
      urlObj.hostname === 'vimeo.com'
    ) {
      const videoId = urlObj.pathname.split('/').pop();
      if (videoId) {
        return `https://player.vimeo.com/video/${videoId}`;
      }
    }

    return null;
  } catch {
    return null;
  }
};

export const getLoomEmbedUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (urlObj.hostname === 'www.loom.com') {
      const pathParts = urlObj.pathname.split('/');
      const videoId = pathParts[pathParts.length - 1];
      return `https://www.loom.com/embed/${videoId}`;
    }

    return null;
  } catch {
    return null;
  }
};

export const getVideoEmbedUrl = (url: string): string | null => {
  const youtubeEmbedUrl = getYouTubeEmbedUrl(url);
  if (youtubeEmbedUrl) {
    return youtubeEmbedUrl;
  }

  const vimeoEmbedUrl = getVimeoEmbedUrl(url);
  if (vimeoEmbedUrl) {
    return vimeoEmbedUrl;
  }

  const loomEmbedUrl = getLoomEmbedUrl(url);
  if (loomEmbedUrl) {
    return loomEmbedUrl;
  }

  return null;
};

export const allowedImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/bmp',
  'image/svg+xml',
  'image/tiff',
  'image/heif',
  'image/heic',
  'image/avif',
];
